<template>
  <g fill="#E5472D" fill-rule="nonzero">
    <path
      d="M38 20c0-9.941-8.059-18-18-18S2 10.059 2 20s8.059 18 18 18 18-8.059 18-18zm2 0c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
    />
    <path d="M10.293 11.707a1 1 0 1 1 1.414-1.414l18 18a1 1 0 0 1-1.414 1.414l-18-18z" />
    <path d="M28.293 10.293a1 1 0 0 1 1.414 1.414l-18 18a1 1 0 1 1-1.414-1.414l18-18z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
